import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import IconDeposit from "app/pages/.shared/static/icons/IconDeposit";
import { isDepositEnabled } from "app/pages/.shared/Deposit/depositSelector";

import "./DepositAvailable.scss";

export const DepositAvailable = ({ isDepositEnabled, shop }) => {
	const [lang] = shop.split("-");

	return (
		isDepositEnabled && (
			<div className="deposit-available">
				<IconDeposit width={20} />
				<div className="deposit-available__label">
					{lang === "fr" ? (
						<React.Fragment>
							<span className="deposit-available__label-prefix">
								<FormattedMessage id="my.bookings.deposit.available.label.prefix" />
							</span>
							<FormattedMessage id="my.bookings.deposit.available.label.suffix" />
						</React.Fragment>
					) : (
						<FormattedMessage id="my.bookings.deposit.available.label" />
					)}
				</div>
			</div>
		)
	);
};
const mapStateToProps = state => {
	return {
		isDepositEnabled: isDepositEnabled(state),
		shop: state.shop,
	};
};

DepositAvailable.propTypes = {
	isDepositEnabled: PropTypes.bool,
	shop: PropTypes.string,
};

DepositAvailable.defaultProps = {
	shop: "",
};

export default connect(mapStateToProps)(DepositAvailable);
