import React from "react";
import PropTypes from "prop-types";
import ContactOurExperts from "app/pages/.shared/ContactOurExperts";
import "./dont-leave.scss";

const DontLeave = ({ children, message }) => {
	return (
		<div className="dont-leave">
			<div className="dont-leave__contact">
				<ContactOurExperts message={message} />
			</div>

			{children ? <div className="dont-leave__button">{children}</div> : false}
		</div>
	);
};

DontLeave.propTypes = {
	message: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default DontLeave;
